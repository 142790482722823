import { Link } from "gatsby";
import React from "react";
import Facebook from "../svg/Facebook";
import Instagram from "../svg/Instagram";

/* Footer
============================================================================= */
const Footer = () => {
	return (
		<footer
			id="contact"
			className="bg-black py-16 m-0 z-20 w-full text-white"
		>
			<div className="container mx-auto flex flex-col md:flex-row items-center justify-around md:justify-center">
				<div className="text-3xl orangetext md:w-1/4 w-full text-center pb-4 md:pb-0">
					CONTACT US
				</div>
				<div className="flex flex-col md:flex-row items-start justify-center md:justify-around md:w-3/4 w-full">
					<div className="flex flex-col items-center justify-start md:justify-around w-full pb-10 md:pb-0">
						<div className="text-xl ">WA</div>
						<div className="text-xl ">Off The Vine</div>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="mailto:orders@offthevine.net.au"
							className="text-xl underline"
						>
							orders@offthevine.net.au
						</a>
						<div className="text-xl">(08) 9418 8210</div>
					</div>
					<div className="flex flex-col items-center justify-start md:justify-around w-full">
						<div className="text-xl ">NSW</div>
						<div className="text-xl ">Young & Rashleigh</div>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="mailto:wines@youngandrashleigh.com"
							className="text-xl underline"
						>
							wines@youngandrashleigh.com
						</a>
						<div className="text-xl">(02) 9967 5900</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

/* Export
============================================================================= */

export default Footer;
