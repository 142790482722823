import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Header from "./header";
import Footer from "./footer";
import SEO from "../components/seo";
import "../styles/styles.scss";
import "../styles/carousel.scss";
import "pure-react-carousel/dist/react-carousel.es.css";

/* Layout
============================================================================= */

export default ({ children, pageContext }) => {
	return (
		<>
			<SEO title="Puppet Master Wines" />
			<Header />
			<main>{children}</main>
			<Footer />
		</>
	);
};
