import React from "react";

/* Facebook
============================================================================= */

const Facebook = () => {
	// Config

	// Render
	return (
		<svg
			width="24"
			height="25"
			viewBox="0 0 32 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M31.999 3.50377V28.6466C31.999 30.5395 30.4633 32.0752 28.5705 32.0752H22.4776V19.4181H26.8062L27.4276 14.5895H22.4776V11.5038C22.4776 10.1038 22.8633 9.15377 24.8705 9.15377H27.4276V4.83948C26.9847 4.78234 25.4705 4.64662 23.699 4.64662C20.0133 4.64662 17.4847 6.89662 17.4847 11.0323V14.5966H13.1419V19.4252H17.4919V32.0752H3.4276C1.53474 32.0752 -0.000976562 30.5395 -0.000976562 28.6466V3.50377C-0.000976562 1.61091 1.53474 0.0751953 3.4276 0.0751953H28.5705C30.4633 0.0751953 31.999 1.61091 31.999 3.50377Z"
				fill="#fff"
			/>
		</svg>
	);
};

/* Export
============================================================================= */

export default Facebook;
