import { Link } from "gatsby";
import React from "react";
import Facebook from "../svg/Facebook";
import Instagram from "../svg/Instagram";
import Hamburger from "./hamburger";

/* Header
============================================================================= */
const Header = () => {
	return (
		<header className="headercolour m-0 z-20 absolute w-full h-14 text-white">
			<div className="flex container mx-auto px-8 lg:px-16 flex-row items-center justify-between md:justify-around py-5">
				<div>
					<img
						className="h-6 md:h-8 object-contain"
						src={`PM_Logo.png`}
						alt="Puppetmaster Logo"
					/>
				</div>
				<a href="#hero" className="hidden md:block text-md ">
					HOME
				</a>
				<a href="#about" className="hidden  md:block text-md ">
					ABOUT
				</a>
				<a href="#wines" className="hidden  md:block text-md">
					WINES
				</a>
				<a href="#stockists" className="hidden  md:block text-md">
					STOCKISTS
				</a>
				<a href="#contact" className="hidden  md:block text-md">
					CONTACT
				</a>
				<div className="flex flex-row items-center justify-around"></div>
			</div>
		</header>
	);
};

/* Export
============================================================================= */

export default Header;
